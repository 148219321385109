.t365 {
  .apidata .revenueBoxes {
    background-image: linear-gradient(#25282a, #4b4d48);
  }

  .apidata .revenue-details {
    width: 100%;
    -webkit-box-shadow: 0px -5px 6px 0px #25282a;
    -moz-box-shadow: 0px -5px 6px 0px #25282a;
    box-shadow: 0px -5px 6px 0px #25282a;
    background-color: #25282a;
  }

  .apidata .panel-heading {
    background-color: #25282a!important;
  }

  .dashboard-user-item {
    background-image: linear-gradient(#25282a, #4b4d48);
  }

  .logo-text {
    color: #25282a;
  }
}
  