.trustbucks {
  .apidata .panel-heading {
    background-color: #263E0F !important;
  }

  .btn.defaultBtn {
    background-color: #D29D02 !important;
  }

  .apidata .revenueBoxes {
    background-image: linear-gradient(#D29D02, #dec068);
  }

  .apidata .revenue-details {
    -webkit-box-shadow: 0px -5px 6px 0px #dec068;
    -moz-box-shadow: 0px -5px 6px 0px #dec068;
    box-shadow: 0px -5px 6px 0px #dec068;
    background-color: #dec068;
  }

  .logotype {
    background: #192C29;
  }

  .table-component .header-active {
    background: #263E0F;
  }

  .apidata .proximity .proximity-remain {
    background: #4E5B6C;
  }

  .apidata #myBar {
    background: #084f9d;
  }


  .apidata .allSides {
    box-shadow: 0 0 50px rgba(46, 204, 113, 1);
    -moz-box-shadow: 0 0 20px rgba(46, 204, 113, 1);
    -webkit-box-shadow: 0 0 20px rgba(46, 204, 113, 1);
    -o-box-shadow: 0 0 20px rgba(46, 204, 113, 1);
  }

  .dashboard-user-item {
    background-image: linear-gradient(#223679, #1b60ab);
  }
}