.panel-block {
  width: 100%;
  padding: 15px 15px 0 15px;
}

.my-account .input-column {
  width: 200px;
  min-width: 130px;
  margin: 10px;
  position: relative;
}

/*.my-account .*/

.my-account .input-column input {
    padding-right: 30px;
}

.my-account .input-column input[disabled],
.my-account .input-column input[disabled] + .percent-sign {
    color: #979797;
}

.my-account .change-password-title {
  font-weight: bold;
  font-size: 16px;
}

.my-account .input-column .percent-sign-complex {
  position: absolute;
  top: 38px;
  right: 10px;
}

.my-account .error-message {
  font-size: 14px;
  color: red;
}

.my-account .error-field {
  border: 2px solid red;
}

@media screen and (max-width: 610px) {
  .my-account .input-column .percent-sign-complex {
    right: 20px;
  }
}