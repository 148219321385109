.trustbucks {
    @font-face {
        font-family: 'Titillium Web';
        src: url('../../fonts/TitilliumWeb-Regular.ttf') format('truetype');

        font-style: normal;
        font-weight: normal;
        text-rendering: optimizeLegibility;
    }

    body {
        font-family: 'Titillium Web', sans-serif;
        margin: 0px;
    }

    .toggler-container {
        height: 25px;
        width: 60px;
        border: 2px solid #F2F2F2;
        background-color: #F2F2F2;
        border-radius: 25% / 50%;
        position: relative;
        cursor: pointer;
        transition-property: background-color;
        transition-duration: 0.5s;

        &.active {
            background-color: #32CD32;

            .slider {
                left: calc(50% + 3px);
            }
        }
    }

    .toggler-container .slider-container {
        height: 0;
        width: calc(40% - 4px);
        border: 16px solid #FFFFFF;
        position: absolute;
        top: -5px;
        left: -7px;
        box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1),
            0 0 0 2px rgb(0, 0, 0, 0.1),
            0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
        border-radius: 50%;

        transition-property: left;
        transition-duration: 0.5s;
    }

    .toggler-container.active .slider-container {
        top: -5px;
        left: calc(50% + 2px);
    }

    .toggler-container.active .toggler-state {
        color: #FFFFFF;
        float: left;
        margin-left: 4px;
        margin-top: 2px;
        font-size: 12px;
    }

    .toggler-container .toggler-state {
        color: #A9A9A9;
        margin-right: 4px;
        margin-top: 2px;
        float: right;
        font-size: 12px;
    }

    .toggler-container.active {
        background-color: #263E0F;
    }

    .toggler-container.active .slider {
        left: calc(60% + 2.5px);
        background-color: #263E0F;
    }

    .toggler-container .slider {
        position: absolute;
        width: calc(42% - 4px);
        height: calc(100% - 2px);
        border-radius: 50%;
        top: 1px;
        left: -1px;
        background-color: #263E0F;

        transition-property: left;
        transition-duration: 0.5s;
    }

    .navBar {
        height: 60px;
        background-color: #FFFFFF;
        border-bottom: 1px solid #ccc;
        position: fixed !important;
        top: 0;
        width: 100%;
        z-index: 9;
    }

    .navBar .burger-content .menu .header {
      background-color: #263E0F;
    }

    .navBar .burger-content .menu .menuItem.active {
      color: #263E0F;
    }

    .navBar .burger-content .logout-item {
      border-top: 1px solid #263E0F;
    }

    .navBar .burger-container .burger-bar1,
    .navBar .burger-container .burger-bar2,
    .navBar .burger-container .burger-bar3 {
      background-color: #263E0F;
    }

    .icon {
        min-width: 70px;
    }

    .navBar .group-icons {
        margin-right: 15px;
        margin-left: 15px;
    }

    .navBar .group-icons>div {
        margin-right: 15px;
        cursor: pointer;
    }

    .navBar .image {
        margin-left: 15px;
    }

    .navBar .group-icons a {
        text-decoration: none;
        color: #414141;
    }

    .navBar>a {
        margin-left: 10px;
        margin-right: 10px;
    }

    .content {
        min-height: 100vh;
        position: relative;
    }

    .sidebar {
        width: 220px;
        background-color: #FFFFFF;
        padding-top: 60px;
        position: sticky;
        top: 0;
        left: 0;
    }

    .module {
        background-color: #e0e0e7;
        z-index: 1;
        height: 111%;
    }

    .sidebar .header {
        background-color: #263E0F !important;
        color: #FFFFFF;
        padding: 10px 7px 10px 20px;
    }

    .sidebar .menublock {
        padding-bottom: 10px;
    }

    .sidebar .list-menus {
        font-size: 14px;
    }

    .sidebar .menuItem {
        padding: 10px 7px 10px 20px;
        text-decoration: none;
        color: #414141;
    }

    .sidebar .menuItem.active {
        background-color: #f1f1f1;
        color: #263E0F;
        font-weight: bold;
    }

    .content .panel-default>.panel-heading {
        color: #fff;
        background-color: #263E0F;
        border-color: #ddd;
    }

    .content .panel-default>.panel-heading.step2 {
        background-color: #a0a0a0;
    }

    .content .panel-default {
        min-height: 170px;
    }

    .form-control,
    .Select-control {
        height: 44px;
    }

    .Select-placeholder,
    .Select--single>.Select-control .Select-value {
        line-height: 43px;
    }

    .btn {
        padding: 11px 12px;
    }

    .btn-secondary {
        background-color: #CCCCCC;
        color: #FFFFFF;
    }

    .btn.defaultBtn {
        color: #fff;
        text-transform: uppercase;
        background-color: #63d311;
        outline: 0;
    }

    /*POPUP*/
    .popup-window {
        color: #31708f;
        overflow-wrap: break-word;
        background-color: #d9edf7;
        border-color: #bce8f1;
        margin-top: 70px;
        min-width: 300px;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 15px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    }


    /**
       * Date time picker
       *
       */

    .rdt {
        position: relative;
    }

    .rdtPicker {
        display: none;
        position: absolute;
        width: 250px;
        padding: 4px;
        margin-top: 1px;
        z-index: 99999 !important;
        background: #fff;
        -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
        box-shadow: 0 1px 3px rgba(0, 0, 0, .1);
        border: 1px solid #f9f9f9;
    }

    .rdtOpen .rdtPicker {
        display: block;
        right: 0px;
    }

    .rdtStatic .rdtPicker {
        -webkit-box-shadow: none;
        box-shadow: none;
        position: static;
    }

    .rdtPicker .rdtTimeToggle {
        text-align: center;
    }

    .rdtPicker table {
        width: 100%;
        margin: 0;
    }

    .rdtPicker td,
    .rdtPicker th {
        text-align: center;
        height: 28px;
    }

    .rdtPicker td {
        cursor: pointer;
    }

    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtHour:hover,
    .rdtPicker td.rdtMinute:hover,
    .rdtPicker td.rdtSecond:hover,
    .rdtPicker .rdtTimeToggle:hover {
        background: #eeeeee;
        cursor: pointer;
    }

    .rdtPicker td.rdtOld,
    .rdtPicker td.rdtNew {
        color: #999999;
    }

    .rdtPicker td.rdtToday {
        position: relative;
    }

    .rdtPicker td.rdtToday:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-bottom: 7px solid #428bca;
        border-top-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 4px;
        right: 4px;
    }

    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
        background-color: #428bca;
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }

    .rdtPicker td.rdtActive.rdtToday:before {
        border-bottom-color: #fff;
    }

    .rdtPicker td.rdtDisabled,
    .rdtPicker td.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
    }

    .rdtPicker td span.rdtOld {
        color: #999999;
    }

    .rdtPicker td span.rdtDisabled,
    .rdtPicker td span.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
    }

    .rdtPicker th {
        border-bottom: 1px solid #f9f9f9;
    }

    .rdtPicker .dow {
        width: 14.2857%;
        border-bottom: none;
        cursor: default;
    }

    .rdtPicker th.rdtSwitch {
        width: 100px;
    }

    .rdtPicker th.rdtNext,
    .rdtPicker th.rdtPrev {
        font-size: 21px;
        vertical-align: top;
    }

    .rdtPrev span,
    .rdtNext span {
        display: block;
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Chrome/Safari/Opera */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
    }

    .rdtPicker th.rdtDisabled,
    .rdtPicker th.rdtDisabled:hover {
        background: none;
        color: #999999;
        cursor: not-allowed;
    }

    .rdtPicker thead tr:first-child th {
        cursor: pointer;
    }

    .rdtPicker thead tr:first-child th:hover {
        background: #eeeeee;
    }

    .rdtPicker tfoot {
        border-top: 1px solid #f9f9f9;
    }

    .rdtPicker button {
        border: none;
        background: none;
        cursor: pointer;
    }

    .rdtPicker button:hover {
        background-color: #eee;
    }

    .rdtPicker thead button {
        width: 100%;
        height: 100%;
    }

    td.rdtMonth,
    td.rdtYear {
        height: 50px;
        width: 25%;
        cursor: pointer;
    }

    td.rdtMonth:hover,
    td.rdtYear:hover {
        background: #eee;
    }

    .rdtCounters {
        display: inline-block;
    }

    .rdtCounters>div {
        float: left;
    }

    .rdtCounter {
        height: 100px;
    }

    .rdtCounter {
        width: 40px;
    }

    .rdtCounterSeparator {
        line-height: 100px;
    }

    .rdtCounter .rdtBtn {
        height: 40%;
        line-height: 40px;
        cursor: pointer;
        display: block;

        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Chrome/Safari/Opera */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
    }

    .rdtCounter .rdtBtn:hover {
        background: #eee;
    }

    .rdtCounter .rdtCount {
        height: 20%;
        font-size: 1.2em;
    }

    .rdtMilli {
        vertical-align: middle;
        padding-left: 8px;
        width: 48px;
    }

    .rdtMilli input {
        width: 100%;
        font-size: 1.2em;
        margin-top: 37px;
    }

    .rdtTime td {
        cursor: default;
    }

    /* Multiselect styles */

    .multiselect {
        width: 100%;
    }

    .multiselect .multiselect-header {
        background-color: #263E0F;
        color: #FFFFFF;
        padding: 5px 5px 5px 15px;
        border: 1px solid #263E0F;
        border-radius: 10px;
        width: 100%;
    }

    .multiselect .multiselect-body {
        margin: 15px 5px 0 5px;
        width: 100%;
    }

    .multiselect .multiselect-select {
        width: 100%;
    }

    .multiselect .form-control {
        padding: 6px 0px 6px 12px;
    }

    .multiselect .selected-items {
        margin-top: 10px;
    }

    .multiselect .actions {
        width: 150px;
        margin-left: 10px;
    }

    .multiselect .add,
    .delete {
        width: 30px;
        margin: 6px;
        outline: 0;
    }

    .multiselect .error-message {
        margin: 2px;
        color: #FF0000;
    }

    .multiselect .add .plus {
        position: absolute;
        top: 1px;
        left: 10px;
    }

    .multiselect .add {
        position: relative;
        border-radius: 50%;
        box-shadow: 0;
        border: 0;
        font-size: 18px;
        font-weight: bold;
        height: 30px;
        background-color: #263E0F;
        color: #FFFFFF;
    }

    .multiselect .delete {
        border-radius: 50%;
        box-shadow: 0;
        border: 0;
        font-size: 12px;
        font-weight: bold;
        height: 30px;
        background-color: #CCCCCC;
        color: #FFFFFF;
    }

    .swal2-popup {
        font-size: 1.6rem !important;
    }

    .form-control[disabled] {
        -moz-appearance: none;
        appearance: none;
    }

    .table-component table {
        table-layout: fixed;
    }

    .table-component table>thead>tr>th {
        cursor: pointer;
        vertical-align: middle;
    }

    .table-component .icon-span,
    .text-span {
        display: inline-block;
        vertical-align: middle;
    }

    .table-component .header-active {
        background: #263E0F;
        color: #FFFFFF;
    }



    /* Responsive styles */

    @media screen and (min-width: 1000px) {
        .two-inputs-column {
            width: 65%;
        }

        .fxSettings .input-column {
            width: 220px;
        }

        .wrap-rule br {
            display: none;
        }

        .navBar .burger-container {
            display: none;
        }
    }

    @media screen and (max-width: 1199px) {
        .two-inputs-column {
            width: 65%;
        }

        .fxSettings .input-column {
            width: 220px;
        }

        .wrap-rule br {
            display: none;
        }

        .addClient .extra-column {
            display: none !important;
        }

        .editClient .extra-column {
            display: none !important;
        }
    }

    @media screen and (max-width: 991px) {
        .apidata .dashboard-row {
            flex-direction: column !important;
        }

        .content .group-icons {
            display: none !important;
        }

        .sidebarColumn {
            display: none !important;
        }

        /*.apidata .revenueBoxes, .apidata .input-column {
            width: 49%
          }*/
        .apiData .table-column,
        .apiData .balance {
            width: 100% !important;
            margin-bottom: 15px;
        }

        .fxSettings .input-column {
            width: 100%;
            margin-right: 0;
        }

        .two-inputs-column {
            width: 100%;
        }

        .big-wrap-row {
            margin: 0;
        }

        .big-wrap-row img {
            max-width: 265px;
        }

        .two-inputs-column .input-column:first-of-type {
            margin-right: 10px;
        }
    }

    @media screen and (max-width: 767px) {
        .fxFAQ .input-column:first-of-type {
            margin-right: 2%;
        }

        .apidata .fullwidth-button {
            width: 100%;
            padding: 0 20px 0 20px;
        }

        .apidata .defaultBtn {
            width: 100%;
        }

        .multiselect .multiselect-select {
            width: 100%;
        }

        /*.multiselect .actions {
            width: 40%;
          }*/
    }
}